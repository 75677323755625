.rhf-form {
  display: flex;
  flex-direction: column;
}

.rhf-form__array-item > div {
  border-width: 1px 1px 1px 5px;
}

.rhf-form__array-item:nth-child(3n + 1) > div {
  border-color: var(--primary);
}

.rhf-form__array-item:nth-child(3n + 2) > div {
  border-color: var(--secondary);
}

.rhf-form__array-item:nth-child(3n + 3) > div {
  border-color: var(--text);
}

.RHF-date {
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}
