body,
html {
  overflow-x: hidden;
  overflow-x: clip;
}

body {
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - var(--nav-width));
  max-width: calc(100% - var(--nav-width));
  min-height: calc(var(--vh) * 100);
  padding: 20px;
  float: right;
}

.wrap {
  width: 100%;
  max-width: 1920px;
  padding: 0 40px;
  margin: 0 auto;
}

.wrap--md {
  max-width: 1488px;
}

.wrap--sm {
  max-width: 1328px;
}

.wrap--xs {
  max-width: 1008px;
}

.section {
  padding: 160px 0;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.icon {
  width: 16px;
  height: 16px;
}

.icon-large {
  width: 24px;
  height: 24px;
}

.flip-x {
  transform: scaleX(-1);
}

.flip-y {
  transform: scaleY(-1);
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cover-img,
.contain-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cover-img {
  object-fit: cover;
}

.contain-img {
  object-fit: contain;
}

.form-header {
  position: sticky;
  top: 20px;
  z-index: 1;
}

@media only screen and (max-width: 899px) {
  .wrap {
    padding: 0 16px;
  }

  .wrap--md {
    padding: 0 24px;
  }

  .section {
    padding: 80px 0;
  }
}
