.btn {
  display: inline-flex;
  align-items: center;
  padding: 15px 24px;
  font-size: 1.3rem;
  line-height: 1.5;
  border-radius: 2px;
  border: none;
  outline-offset: 3px;
  font-family: 'Plain';
  transition: border-radius var(--transition);
}

.btn .loader {
  margin-left: 2rem;
  font-size: 0.3rem;
}

.btn:hover,
.btn:focus-visible {
  border-radius: 60px;
}

.btn--primary {
  color: white;
  background: var(--primary);
}

.btn--primary:hover,
.btn--primary:focus-visible {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--primary);
}

.btn--secondary {
  color: white;
  background-color: var(--secondary);
}

.btn--secondary:hover,
.btn--secondary:focus-visible {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--secondary);
}

.btn--dark {
  color: white;
  background-color: var(--text);
}

.btn--dark:hover,
.btn--dark:focus-visible {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--text);
}

.btn--light {
  color: var(--text);
  background-color: white;
}

.btn--light:hover,
.btn--light:focus-visible {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), white;
}

.btn[disabled] {
  background-color: grey;
}

.link {
  display: inline-block;
  padding: 13px 0;
  font-size: 2rem;
  line-height: 1.5;
  font-family: 'Plain';
  border-color: transparent;
  border-width: 2px 0;
  border-style: solid;
  outline-offset: 2px;
}

.link--primary {
  color: var(--primary);
}

.link--primary:hover {
  border-bottom-color: var(--primary);
}

.link--secondary {
  color: var(--secondary);
}

.link--secondary:hover {
  border-bottom-color: var(--secondary);
}

.link--light {
  color: white;
}

.link--light:hover {
  border-bottom-color: white;
}

.link--dark {
  color: var(--text);
}

.link--dark:hover {
  border-bottom-color: var(--text);
}

.talbe-btn {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  gap: 6px;
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--text);
}

.talbe-btn svg {
  font-size: 1rem;
}
