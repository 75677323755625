:root {
  --primary: #218ce7;
  --primary100: #d5dde9;
  --secondary: #ff3b4b;
  --text: #233b4b;
  --surface-light: #f2f3f6;
  --surface300: #233c4b;
  --success: #7be085;
  --success200: #2bb539;
  --success-text: #1b7324;
  --error: #ffa8a8;
  --error200: #cc0000;
  --error-text: #ad1f1f;
  --warning: #ffcb66;
  --warning200: #ea9a00;
  --warning-text: #ad7200;

  --transition: 300ms ease-out;

  --nav-width: 250px;
  --nav-collapsed-width: 280px;

  --chakra-shadows-outline: 0 0 0 3px black !important;
}
