.tag-field__container {
  position: relative;
}

.tag-field__options {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  z-index: 1;
}

.tag-field__option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex: 0 0 auto;
  text-align: left;
  padding: 10px;
  overflow: hidden;
}

.tag-field__option:hover {
  background-color: var(--surface-light);
}

.tag-field__option-info {
  opacity: 0.7;
  font-size: 0.8rem;
}
