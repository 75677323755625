.h1,
.h2,
.h3,
.h4 {
  letter-spacing: -0.05em;
  font-weight: 600;
}

.h1 {
  font-size: 7rem;
  line-height: 1.15;
}

.h2,
.markdown h1 {
  font-size: 5rem;
  line-height: 1.15;
}

.h3,
.markdown h2 {
  font-size: 2.5rem;
  line-height: 1.25;
}

.h4,
.markdown h3 {
  font-size: 1.5rem;
  line-height: 1.25;
}

.body-large {
  font-size: 1.5rem;
}

.body-small {
  font-size: 1rem;
}

.tag {
  display: inline-block;
  padding: 8px;
  background-color: var(--primary100);
  font-size: 1rem;
  border-radius: 24px;
}

.markdown h2 {
  margin-bottom: 40px;
}

.markdown p {
  margin: 30px 0;
}

@media only screen and (max-width: 899px) {
  .h1 {
    font-size: 4rem;
  }

  .h2 {
    font-size: 2.5rem;
  }

  .h3 {
    font-size: 2rem;
  }
}
