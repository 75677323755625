#modal {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(35, 59, 75, 0.3);
  z-index: 110;
}

.modal:not(.modal--right) {
  overflow-y: auto;
}

.modal__overflow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 120px 0;
  width: 100%;
  min-height: 100%;
}

.modal__overflow > div {
  max-width: 937px;
  width: calc(100% - 32px);
}

.modal__container {
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.modal--right .modal__container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 800px;
}

.modal-enter {
  opacity: 0;
}
.modal-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.modal-exit {
  opacity: 1;
}
.modal-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.modal-enter.modal--right .modal__container {
  transform: translateX(100%);
}
.modal-enter-active.modal--right .modal__container {
  transform: translateX(0);
  transition: 300ms;
}
.modal-exit.modal--right .modal__container {
  transform: translateX(0);
}
.modal-exit-active.modal--right .modal__container {
  transform: translateX(100%);
  transition: 300ms;
}

@media only screen and (max-width: 899px) {
  .modal--right .modal__container {
    width: calc(var(--vw) * 100);
  }
}
