.input {
  margin-bottom: 16px;
}

.input__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 16px;
  margin-bottom: 4px;
}

.input__link {
  text-decoration: underline;
}

.input__label {
  margin-right: auto;
  display: block;
  font-size: 1rem;
}

.input__wrapper {
  display: flex;
  position: relative;
}

.input__field {
  width: 100%;
  padding: 14px 11px;
  line-height: 1.5;
  background-color: white;
  color: var(--text);
  font-family: 'Plain';
  border: 1px solid var(--primary100);
  border-radius: 2px;
  font-size: 1.2rem;
}

.input__field:disabled {
  opacity: 0.6;
  background-color: var(--primary100);
}

.input.with-icon .input__field,
.input__field--password,
.input__field--select {
  padding-right: 40px;
}

.input__field:focus-visible {
  padding: 13px 10px;
  border: 2px solid var(--primary);
}

.input__field::placeholder {
  color: var(--text);
  opacity: 0.5;
}

.input.error .input__field,
.input__field.error {
  border-color: var(--error200);
  box-shadow: 0 0 5px red;
}

.input__error {
  margin-top: 4px;
  color: var(--error-text);
}

.input__btn {
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
  padding: 5px;
  border: none;
  color: var(--primary);
}

.input__btn svg {
  display: block;
}

.input__field--select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.input__field--select::-ms-expand {
  display: none;
}

.input__field--select:invalid {
  color: rgba(35, 59, 75, 0.5);
}

.input__field--select option {
  color: var(--text);
}

.input__field--select option[value=''] {
  color: rgba(35, 59, 75, 0.5);
}

.input__icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: var(--primary);
  user-select: none;
  pointer-events: none;
}

.input--checkbox {
  position: relative;
  padding-left: 40px;
}

.input__checkbox:not(:checked) + .input__checkmark-icon {
  display: none;
}

.input__checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.input__checkbox-label {
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input__checkbox-label a {
  text-decoration: underline;
}

.input__checkbox-label a:hover {
  color: var(--primary);
}

.input__checkbox:focus-visible ~ .input__checkmark {
  border-width: 2px;
  border-color: var(--primary);
}

.input__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: white;
  border: 1px solid var(--primary100);
}

.input__checkmark-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
}

.image-btn {
  flex-shrink: 0;
  margin: 12px 0;
  padding: 16px;
  background-color: var(--surface-light);
  border-radius: 12px;
}

.image-btn.error {
  border: 1px solid red;
  padding: 15px;
}

.input__wrapper.error .ref-button {
  border: 1px solid var(--error200);
  box-shadow: 0 0 5px red;
}

/* Google */
.pac-container {
  font-family: 'Plain';
}

.pac-item {
  padding: 10px 4px;
  font-size: 1rem;
  line-height: 1.5;
}

.pac-item-query {
  font-size: 1rem;
  padding-right: 8px;
}

.pac-icon {
  margin: 4px 5px 0 0;
}
