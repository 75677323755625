.upload {
  position: relative;
  background-color: var(--surface-light);
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 3px dashed var(--text);
  border-radius: 12px;
  text-align: center;
  margin-bottom: 40px;
  overflow: hidden;
  cursor: pointer;
}

.upload--over {
  border-style: solid;
}

.upload__field {
  display: none;
}

.upload__thumbnail {
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
}

.upload__name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}
