.nav {
  position: fixed;
  top: 0;
  left: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: var(--nav-width);
}

.nav__logo {
  height: 32px;
}

.nav nav {
  display: flex;
  flex-direction: column;
  height: 100%;
}
