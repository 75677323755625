.home {
  position: relative;
  flex: 1 0 100%;
}

.home__logo-container {
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: 700px;
  height: 700px;
  z-index: -1;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
}

.home__logo {
  position: absolute;
  bottom: -350px;
  right: -350px;
  width: 1000px;
  max-width: none;
  opacity: 0;
  animation: rotate 25s infinite linear, fade-in 4s forwards ease 1s;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
